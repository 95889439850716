import { flatten, unflatten } from 'flat'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const serializeFilters = <T = any>(value: T) => {
  if (!value) return ''
  return JSON.stringify(flatten(value))
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deserializeFilters = <T = any>(
  value: string
): T | undefined | null => {
  if (!value) return null
  return unflatten(JSON.parse(value))
}
